import * as React from "react"

import Layout from "../components/layout"
import PageHeader from "../components/pageheader"
import PageBody from "../components/pagebody"
import Seo from "../components/seo"

const ContactSentPage = ({ location }) => (
  <Layout currPage="contactsent" location={location}>
    <Seo title="Message sent" />
    <PageHeader pageTitle="Message sent" />
    <PageBody>
      <p>Your message has been sent. We will get back to you soon.</p>
    </PageBody>
  </Layout>
)

export default ContactSentPage
